// copy of templates/index-page.js

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { purple, red } from '@mui/material/colors';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import Layout from '../components/Layout';

import Content, { HTMLContent } from '../components/Content';

import HeroSection from '../components/HeroSection';
import AboutSection from '../components/AboutSection';
import UpdateList from '../components/UpdateList';
// import ProductList from '../components/ProductList';

import Productive from '../img/being-productive.mp4';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	zIndex: 1,
});

const MainContent = styled(Box)({
	// marginTop: 'auto',
	// marginBottom: '50px',
	margin: 'auto 64px 50px',
});

const FeatureOne = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: theme.palette.paper,
	padding: theme.spacing(12),
}));

const CardOne = styled(Box)(({ theme, props }) => ({
	display: 'flex',
}));

// const Purple = purple[200];

// eslint-disable-next-line
export const ALEiGNLandingTemplate = ({
	image,
	title,
	heading,
	subheading,
	mainpitch,
	description,
	intro,
	content,
	contentComponent,
}) => {
	const heroImage = getImage(image) || image;
	const PageContent = contentComponent || Content;

	return (
		<>
			<FeatureOne>
				<Container>
					<Grid
						container
						item
						xs={12}
						lg={8}
						justifyContent='center'
						mx='auto'
						textAlign='center'
						pb={4}
					>
						<Grid item>
							<Typography variant='h1' mb={1}>
								Let's get productive!
							</Typography>
							<Typography
								variant='h4'
								color='text'
								sx={{ textTransform: 'none', mt: -1 }}
							>
								with ALEiGN{' '}
								<Typography variant='jesus' color='primary'>
									#move2done!
								</Typography>
							</Typography>
							<Typography variant='body1' my={2} component='p' align='center'>
								Productivity Software with Positive ATTITUDE!
							</Typography>
							<Typography my={2} component='h5' variant='h5' sx={{ lineHeight: 1 }}>
								We've been busy <br />
								<Typography
									variant='caption'
									sx={{ textTransform: 'lowercase', fontSize: '1rem' }}
								>
									(good busy = #move2done!)
								</Typography>{' '}
							</Typography>
							<Typography color='primary' my={2} variant='h3' sx={{ lineHeight: 1 }}>
								{' '}
								How is your busy?
							</Typography>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item>
							<Typography variant='h3' align='center'>
								I'll show you mine first
							</Typography>
							{/* <Typography variant='body1' my={2} component='p' align='center' mb={4}>
								Relationships are all about 4 things; #context #comms #culture
								#move2done! <br />
								And you thought we were another todo list.
							</Typography> */}
							<Typography variant='h5' align='center' mb={8}>
								Here a few new features we use to support{' '}
								<a href='aleign-open4biz#mantra'>our mantra</a>.
							</Typography>
							<UpdateList />
						</Grid>
					</Grid>

					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							zIndex: -1,
							opacity: 0.3,
							height: '100%',
							width: '100%',
							overflow: 'hidden',
							video: { height: '100%', width: 'auto' },
						}}
					>
						<video loop autoplay='true'>
							<source src={Productive} type='video/mp4' />
						</video>
					</Box>
				</Container>
			</FeatureOne>

			{/* <FeatureOne>
				<Container>
					<Grid container spacing={6}>
						<Grid item xs={12} md={6}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
											<RemoveRedEyeTwoToneIcon fontSize='large' />
										</Avatar>
									}
									title='Check our team'
									titleTypographyProps={{ variant: 'h3' }}
									subheader='People in this world shun people for being great. For being a bright color. For standing out.'
									subheaderTypographyProps={{ variant: 'body1' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
							</CardOne>
						</Grid>
						<Grid item xs={12} md={6}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
											R
										</Avatar>
									}
									title='Check our team'
									titleTypographyProps={{ variant: 'h3' }}
									subheader='People in this world shun people for being great. For being a bright color. For standing out.'
									subheaderTypographyProps={{ variant: 'body1' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
							</CardOne>
						</Grid>
						<Grid item xs={12} md={6}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
											R
										</Avatar>
									}
									title='Check our team'
									titleTypographyProps={{ variant: 'h3' }}
									subheader='People in this world shun people for being great. For being a bright color. For standing out.'
									subheaderTypographyProps={{ variant: 'body1' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
							</CardOne>
						</Grid>
						<Grid item xs={12} md={6}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
											R
										</Avatar>
									}
									title='Check our team'
									titleTypographyProps={{ variant: 'h3' }}
									subheader='People in this world shun people for being great. For being a bright color. For standing out.'
									subheaderTypographyProps={{ variant: 'body1' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
							</CardOne>
						</Grid>
					</Grid>
				</Container>
			</FeatureOne> */}
			{/* <Section
				py={12}
				className='fullWithSection'
				sx={{ backgroundColor: '#f9faf6', color: '#000' }}
			>
				<MainContent maxWidth='lg' className='column is-10 is-offset-1'>
					<Grid container className='content' spacing={3}>
						<Grid item xs={12} sm={12}>
							<Typography variant='h1'>{mainpitch.title}</Typography>
							<Typography variant='h3'>{mainpitch.description}</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography variant='h3'>{heading}</Typography>
							<Typography variant='body1'>{description}</Typography>
						</Grid>
						<Features gridItems={intro.blurbs} />
						<Button size='large' variant='contained' component={Link} to='/products'>
							See all products
						</Button>
						<Grid item xs={12} sm={12}>
							<Typography variant='h3'>Latest stories</Typography>
							<BlogRoll />
							<ProductList />
							<Button variant='contained' component={Link} to='/blog' size='large'>
								Read more
							</Button>
						</Grid>
					</Grid>
				</MainContent>
			</Section> */}
		</>
	);
};

ALEiGNLandingTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const ALEiGNLanding = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<ALEiGNLandingTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				intro={frontmatter.intro}
				contentComponent={HTMLContent}
				content={post.html}
			/>
		</Layout>
	);
};

ALEiGNLanding.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ALEiGNLanding;

export const pageQuery = graphql`
	query ALEiGNLandingTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "ALEiGN-landing" } }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description
				intro {
					blurbs {
						text
						heading
					}
					heading
					description
				}
			}
		}
	}
`;
